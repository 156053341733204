<template>
  <VRow class="mx-7 mt-3">
    <TopRestaurantNew />
    <TopRestaurantList />
  </VRow>
</template>

<script>
import TopRestaurantNew from '@/components/TopRestaurantNew.vue';
import TopRestaurantList from '@/components/TopRestaurantList.vue';

export default {
  components: {
    TopRestaurantNew,
    TopRestaurantList,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/page.scss';

.larger {
  font-size: 17px;
}
</style>
