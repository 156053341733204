<template>
  <VCol cols="12">
    <VSimpleTable class="elevation-2" fixed-header>
      <thead>
        <tr class="titles-thead">
          <th class="city-column">
            <span>Город</span>
          </th>
          <th>
            <span>Название заведения</span>
          </th>
          <th class="text-center">
            <span>Категория</span>
          </th>
          <th class="text-center">
            <span>Начало</span>
          </th>
          <th class="text-center">
            <span>Окончание</span>
          </th>
          <th class="text-center">
            <span>Статус</span>
          </th>
          <th></th>
        </tr>
        <tr class="search-thead">
          <th>
            <VSelect
              v-model="searchedCity"
              background-color="#5B5B5B"
              class="field"
              clearable
              dark
              dense
              hide-details
              item-text="title"
              item-value="title"
              :items="cities"
              label="Все"
              outlined
              prepend-inner-icon="mdi-magnify"
              solo
              @input="fetchFiltered"
            />
          </th>
          <th>
            <VTextField
              v-model="searchedTitle"
              background-color="#5B5B5B"
              class="field"
              clearable
              dark
              dense
              hide-details
              outlined
              prepend-inner-icon="mdi-magnify"
              solo
              @input="fetchFiltered"
            />
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(top, index) in topRestaurants" :key="top.restaurant.pk+index">
          <td>
            {{ getCityTitleByLocation(top.restaurant.location.coordinate) }}
          </td>
          <td class="text-title">
            {{ top.restaurant.title }}
          </td>
          <td class="d-flex icon-td">
            <img
              v-if="top.category.activeIcon"
              alt="icon"
              class="icon"
              :src="top.category.activeIcon"
            >
            <div v-else class="icon" />
            <span class="ml-2">{{ top.category.title || 'В общем списке' }}</span>
          </td>
          <td class="text-center">
            {{ convertDateToView(top.startedAt) }}
          </td>
          <td class="text-center">
            {{ convertDateToView(top.endedAt) }}
          </td>
          <td
            class="text-center"
            :class="topRestaurantStatus(top.startedAt, top.endedAt, index)
              ? statusClass[index]
              : 'await-for-date'"
          >
            {{ statusText[index] }}
          </td>
          <td>
            <VBtn
              class="ml-3 remove-btn"
              icon
              small
            >
              <VIcon
                color="white"
                small
                @click="deleteTop(top.id)"
              >
                mdi-delete
              </VIcon>
            </VBtn>
          </td>
        </tr>
      </tbody>
    </VSimpleTable>
    <div class="d-flex justify-end py-4">
      <VBtn
        class="ma-2"
        color="success"
        :disabled="hasPrevious"
        tile
        @click="fetchPreviousPage"
      >
        <VIcon>mdi-chevron-left</VIcon>
        Предыдущая
      </VBtn>
      <VBtn
        class="ma-2"
        color="success"
        :disabled="hasNext"
        tile
        @click="fetchNextPage"
      >
        Следующая
        <VIcon>mdi-chevron-right</VIcon>
      </VBtn>
    </div>
  </VCol>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { eventHub, events } from '@/global/eventhub';
import { fetchTopRestaurant, deleteTopRestaurant } from '@/api/api';

export default {
  data() {
    return {
      topRestaurants: [],
      searchedCity: null,
      searchedTitle: '',
      statusClass: [],
      statusText: [],
      currentOffset: 0,
      limit: 20,
    };
  },
  created() {
    this.getCities().then(() => {
      this.fetchTops();
    });
    eventHub.$on(events.topCreated, () => this.fetchTops());
  },
  computed: {
    ...mapGetters(['cities']),
    hasPrevious() {
      return this.currentOffset < this.limit;
    },
    hasNext() {
      return this.topRestaurants.length < this.limit;
    },
  },
  methods: {
    ...mapActions(['getCities']),

    fetchPreviousPage() {
      this.currentOffset = Math.max(this.currentOffset - this.limit, 0);
      this.fetchTops();
    },

    fetchNextPage() {
      this.currentOffset += this.limit;
      this.fetchTops();
    },

    fetchFiltered() {
      this.currentOffset = 0;
      this.fetchTops();
    },

    fetchTops() {
      fetchTopRestaurant(
        this.currentOffset,
        this.searchedTitle,
        this.searchedCity,
        this.limit,
      ).then((result) => {
        this.topRestaurants = result;
      });
    },

    checkSearchedLocation(location) {
      return this.getCityTitleByLocation(location) === this.searchedCity || !this.searchedCity;
    },

    checkSearchedTitle(title) {
      return title.toLowerCase().includes(this.searchedTitle.toLowerCase()) || !this.searchedTitle;
    },

    getCityTitleByLocation(location) {
      return this.cities.reduce((init, city) => {
        const contains = city.containsPoint(location.longitude, location.latitude);
        return contains ? city.title : init;
      }, null);
    },

    topRestaurantStatus(startedAt, endedAt, index) {
      const timeTillStart = moment(startedAt).diff(moment(), 'minutes');
      if (timeTillStart >= 1) {
        this.statusText[index] = `Активируется через ${Math.round(timeTillStart / 60)} ${(timeTillStart / 60) > 4 ? 'часов' : 'часа'}`;
        return false;
      }
      this.statusClass[index] = moment().diff(moment(endedAt), 'minutes') < 0 ? 'active' : 'expired';
      this.statusText[index] = moment().diff(moment(endedAt), 'minutes') < 0 ? 'Активно' : 'Истекло';
      return true;
    },

    convertDateToView(date, format = 'dddd, D MMM YYYY') {
      return moment(date).locale('ru').utcOffset(date, true).format(format);
    },

    deleteTop(topPk) {
      deleteTopRestaurant(topPk).then(() => {
        const index = this.topRestaurants.findIndex((el) => el.id === topPk);
        this.$nextTick().then(() => {
          this.topRestaurants.splice(index, 1);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixin.scss';

.icon-td {
  padding: 2.3em !important;
  align-items: center;
  .icon{
    width: 55px;
    height: 55px;
  }
  span{
    font-size: 12px;
  }
}

.active{
  color:$default_green_color;
}

.await-for-date{
  color: $default_gray_title_color;
}

.expired{
  color: $default_red_color;
}

th{
  width:22%;
}

th:last-child{
  width:10%;
}

td{
  font-family: $default_font;
  font-size: .9em !important;
  &:nth-child(2){
    font-weight: 500;
  }
}

.titles-thead{
  height: 50px;
  & .text-center{
    text-align: center;
  }
}

.search-thead {
  padding-top:10px;
  padding-bottom: 20px;
  & th{
    color: white !important;
    background-color: $default_gray_color !important;
    & .field {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}

.remove-btn{
  background-color: red;
  transition:transform .4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  &:hover{
    transform:scale(1.1);
  }
}

</style>
