<template>
  <VCol class="d-flex px-0 flex-wrap" cols="12">
    <VCol class="d-flex flex-wrap" cols="12">
      <span class="page_title"> ТОП-6</span>
    </VCol>
    <VCol class="d-flex align-center" cols="12">
      <span class="page_title middle pr-4">Поднять</span>
      <VCol class="px-0 py-0" cols="4">
        <RestaurantSelection
          input-placeholder="Введите название заведения"
          @restaurantSelected="setPk"
        />
      </VCol>
    </VCol>
    <Transition name="appear">
      <VRow v-if="restaurantPk" no-gutters>
        <VCol cols="5">
          <span class="page_title"> Выберите категорию</span>
        </VCol>
        <VCol cols="5">
          <span class="input-description">
            Выберите категорию, в которой заведение будет располагаться в ТОП-6.
            Если не выбрать категорию - заведение будет в ТОП-6 общего списка.
          </span>
        </VCol>
        <VCol cols="12">
          <VChipGroup
            v-model="chosenCategoryId"
            active-class="primary--text"
            class="d-flex"
            column
          >
            <div
              v-for="category in categories"
              :key="category.id"
              class="icon d-block mx-2"
            >
              <VChip class="btn-image px-0 py-0" :value="category.id">
                <VImg
                  class="icon-background"
                  height="58"
                  :src="chosenCategoryId !== category.id
                    ? category.inActiveIcon
                    : category.activeIcon"
                />
              </VChip>
              <span class="input-description">
                {{ category.title }}
              </span>
            </div>
          </VChipGroup>
        </VCol>
        <VCol cols="12">
          <VCol class="d-flex flex-wrap" cols="12">
            <span class="page_title"> Срок в ТОП-6</span>
          </VCol>
          <VCol class="d-flex flex-wrap" cols="12">
            <VDatePicker
              v-model="dates"
              color="#4CAF50"
              full-width
              :landscape="$vuetify.breakpoint.smAndUp"
              locale="ru"
              range
              selected-items-text="Даты выбраны"
            />
          </VCol>
        </VCol>
        <Transition name="appear">
          <VCol cols="4">
            <VBtn
              class="default-btn white--text w100"
              :color="buttonColor"
              :disabled="dates.length !== 2"
              rounded
              @click="createNewTop"
            >
              Поднять заведение в ТОП
            </VBtn>
          </VCol>
        </Transition>
      </VRow>
    </Transition>
  </VCol>
</template>

<script>
import moment from 'moment';
import { eventHub, events } from '@/global/eventhub';
import { fetchCategories, createTopRestaurant } from '@/api/api';
import RestaurantSelection from '@/components/RestaurantSelection.vue';
import { TopRestaurant } from '@/serializers/topRestaurantSerializer';

export default {
  name: 'TopRestaurantNew',
  components: {
    RestaurantSelection,
  },
  data() {
    return {
      buttonColor: '#4CAF50',
      restaurantPk: null,
      chosenCategoryId: null,
      categories: [],
      dates: [],
    };
  },
  created() {
    fetchCategories(null).then((response) => {
      this.categories = response.sort((a, b) => a.position < b.position);
    });
  },
  methods: {
    setPk(restaurant) {
      this.restaurantPk = restaurant ? restaurant.pk : null;
    },
    createNewTop() {
      this.sortDates();
      const newTop = new TopRestaurant();
      newTop.categoryId = this.chosenCategoryId ? this.chosenCategoryId : undefined;
      newTop.startedAt = moment(`${this.dates[0]} 00:00`, 'YYYY-MM-DD HH:mm').utc().toISOString(true);
      newTop.endedAt = moment(`${this.dates[1]} 23:59`, 'YYYY-MM-DD HH:mm').utc().toISOString(true);

      createTopRestaurant(this.restaurantPk, newTop)
        .then(() => {
          eventHub.$emit(events.topCreated);
          this.buttonColor = '#4CAF50';
          this.restaurantPk = null;
        })
        .catch(() => {
          this.buttonColor = 'red';
        });
    },
    sortDates() {
      this.dates.sort();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/page.scss';

::v-deep{
  .v-image__image--cover{
    transition: background-image 1s cubic-bezier(0, -1, 0, 2);
  }
}

.w100{
  width: 100%;
}

.middle{
  font-size: 20px;
}

.btn-image{
  width: 58px;
  height:58px !important;
  border-radius:50% !important;
  border:1px solid #ECECEC;
}

.icon{
  width: 58px;
  text-align: center;
  cursor: pointer;
  transition:transform .4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  & span{
    font-size:12px !important;
  }
  &:hover {
    transform:scale(1.1);
  }
}

.appear-enter-active,
.appear-leave-active{
  transition: opacity .4s;
}

.appear-enter,
.appear-leave-to{
  opacity: .1;
}

</style>
